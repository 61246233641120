<template>
  <div class="container">
    <el-container class="container-box">
      <el-header height="75px">
        <div class="box">
          <el-image
            style="
              width: 50px;
              height: 50px;
              padding: 5px;
              background: #ffffff;
              border-radius: 8px;
              float: left;
            "
            src="/pc/logo.png"
          ></el-image>
          <span class="header-t">模型树</span>
          <div class="header-btn" style="width: 550px">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
            >
              <el-menu-item index="0" @click="toPage(0)">首页</el-menu-item>
              <el-menu-item index="1" @click="toPage(1)"
                >下载客户端</el-menu-item
              >
              <el-menu-item index="2" @click="toPage(2)">精彩文章</el-menu-item>
              <el-menu-item index="3" @click="toPage(3)">关于我们</el-menu-item>
              <el-menu-item index="4" style="width: 110px">
                <el-button type="primary" class="menu-login" @click="toLogin">{{
                  loginText
                }}</el-button>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div style="height: 322px; margin: 32px auto 70px auto">
            <div style="float: left; width: 720px; position: relative">
              <el-image
                style="border-radius: 8px"
                src="/pc/articles-1.png"
              ></el-image>
              <div style="position: absolute; top: 85px; left: 90px">
                <div>
                  <el-image
                    style="
                      width: 50px;
                      height: 50px;
                      padding: 5px;
                      background: #ffffff;
                      border-radius: 8px;
                      float: left;
                    "
                    src="/pc/logo.png"
                  ></el-image>
                  <span
                    style="
                      margin-left: 20px;
                      font-weight: 900;
                      font-size: 40px;
                      line-height: 56px;
                      letter-spacing: 4px;
                      color: #ffffff;
                    "
                    >模型树</span
                  >
                </div>
                <div
                  style="
                    margin-top: 20px;
                    font-size: 32px;
                    line-height: 45px;
                    color: #ffffff;
                  "
                >
                  在这里爱上深度思考
                </div>
              </div>
            </div>
            <div style="float: right; width: 330px">
              <div style="margin-bottom: 35px">
                <el-image
                  style="width: 330px; height: 140px; border-radius: 8px"
                  src="/pc/articles-2.png"
                ></el-image>
              </div>
              <div>
                <el-image
                  style="width: 330px; height: 140px; border-radius: 8px"
                  src="/pc/articles-3.png"
                ></el-image>
              </div>
            </div>
          </div>
          <div style="margin: 0 auto 60px auto; height: 1466px">
            <div style="float: left; width: 720px">
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <div class="class-box">
                <div class="class-box-l">
                  <div class="class-box-l-t">模型树高阶课</div>
                </div>
                <div class="class-box-r">
                  <div class="class-box-r-t">模型树课程</div>
                  <div class="class-box-r-i">
                    现有三阶课程，帮大家掌握模型树。
                  </div>
                  <div class="class-box-r-i">
                    模型树提供入门课/高阶课/训练营
                  </div>
                  <el-button type="primary" size="small" class="class-box-r-btn"
                    >更多精彩课程<el-icon><i-right /></el-icon
                  ></el-button>
                </div>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                prev-text="上一页"
                next-text="下一页"
                :total="1000"
              >
              </el-pagination>
            </div>
            <div style="float: right; width: 320px">
              <div class="main-box-r">
                <div><span class="el-icon-my-fire"></span>热榜</div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
                <div class="m-box">
                  <div class="m-box-1">1</div>
                  <div class="m-box-2">
                    <div>玩物丧志关于模型树的使用笔记分享</div>
                    <div style="font-size: 10px">
                      <div style="float: left">
                        <el-image
                          class="m-box-img"
                          src="pc/articles-r-img.png"
                        ></el-image>
                      </div>
                      <div class="m-box-a">玩物丧志</div>
                      <div class="m-box-t">2021.10.8</div>
                    </div>
                  </div>
                  <div class="m-box-3">
                    <el-image
                      style="
                        width: 88px;
                        height: 56px;
                        background: #c4c4c4;
                        border-radius: 4px;
                      "
                      src="pc/articles-r-img.png"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-box">
          <div class="des-box">
            <el-descriptions class="margin-top" direction="vertical">
              <el-descriptions-item label="模型树">
                <div style="font-size: 16px; line-height: 22px; color: #666666">
                  关于我们
                </div>
                <div class="des-info">用户协议</div>
                <div class="des-info">隐私声明</div>
              </el-descriptions-item>
              <el-descriptions-item label="联系客服">
                <div class="des-kf-i">
                  扫码联系微信在线客服<br />（周一至周五9：30-16：30）
                </div>
                <el-image src="/pc/wx-kf.png" class="footer-img-1"> </el-image>
              </el-descriptions-item>
              <el-descriptions-item label="微信公众号">
                <div class="des-gzh-i">
                  想要了解更多精彩文章，欢迎关注以下微信公众号
                </div>
                <div>
                  <div class="block">
                    <el-image
                      src="/pc/wx-gzh-1.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">思维有了模型</span>
                  </div>
                  <div class="block">
                    <el-image
                      src="/pc/wx-gzh-2.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">学习有了方法</span>
                  </div>
                  <div class="block" style="margin-right: 0">
                    <el-image
                      src="/pc/wx-gzh-3.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">逑知学研院</span>
                  </div>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="copyright">
            Copyright ©2021 逑知（深圳）教育科技有限公司<br /><a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              >粤ICP备2021136613号</a
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "2",
      isLogin: false,
      loginText: "注册/登录",
    };
  },
  created() {
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin == "true") {
      this.loginText = "已登录";
    } else {
      this.loginText = "注册/登录";
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toPage(i) {
      if (i == 0) {
        this.$router.push("/");
      } else if (i == 1) {
        this.$router.push("/download");
      } else if (i == 2) {
        this.$router.push("/articles");
      } else if (i == 3) {
        this.$router.push("/about");
      }
    },
    toLogin() {
      // this.$api.article.articleTree().then((res) => {
      //   // 保存token
      //   this.$store.commit("setToken", "k2CQGneAdNkJext6O8NiOYW4uKcqsAxyJQmZPxldGjTeW2Kw1cC3OTQFp2ZT4C64");
      //   // 读取token
      //   console.log(this.$store.state.mindmap_token);
      // });
      if (this.isLogin == "true") {
        this.$router.push("/article");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style src="@/assets/bootstrap/css/bootstrap.min.css" scoped></style>
<style scoped>
.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background: #f2f2f2;
}
.container-box {
  width: 1080px;
  margin: 0 auto;
}
.el-header {
  padding: 0;
}
.el-menu {
  background-color: transparent !important;
  border: 0 !important;
}
.box {
  width: 1080px;
  margin: 8px auto;
}
.header-t {
  float: left;
  margin-left: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 60px;
  color: #1380ff;
}
.header-btn {
  float: right;
}
.menu-login {
  border-radius: 20px;
  line-height: 1px;
}
.el-button {
  min-height: 32px !important;
}
.el-menu-item {
  background-color: transparent !important;
  border: 0 !important;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: transparent !important;
  border: 0 !important;
}
.el-menu-item.is-active {
  border: 0 !important;
  font-weight: 900 !important;
  color: #333333 !important;
}
.footer-box {
  margin: 90px auto 0;
  width: 100%;
  height: 370px;
  background: #f7f7f7;
}
.des-box {
  width: 968px;
  margin: 0 auto;
  padding-top: 70px;
}
.des-info {
  margin-top: 30px;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
.footer-img-1 {
  width: 80px;
  height: 80px;
  background: #d8d8d8;
}
.footer-img-2 {
  width: 96px;
  height: 96px;
  background: #d8d8d8;
}
.des-kf-i {
  margin-bottom: 5px;
  color: #666666;
}
.des-gzh-i {
  margin-bottom: 5px;
  color: #666666;
}
.block {
  width: 96px;
  height: 96px;
  margin-top: 12px;
  margin-right: 10px;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.demonstration {
  margin-top: 12px;
  display: block;
  color: #666666;
  font-size: 12px;
  text-align: center;
}
.el-descriptions >>> .el-descriptions__label {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #666666;
}
.el-descriptions >>> .el-descriptions-item__label.has-colon::after {
  content: "";
}
.el-descriptions >>> .el-descriptions__body {
  background: transparent !important;
}
.copyright {
  margin-top: 70px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  color: #666666;
}
.class-box {
  margin-bottom: 40px;
  width: 720px;
  height: 200px;
  background: #ffffff;
  box-shadow: 4px 4px 0px #c8c8c8;
  border-radius: 8px;
}
.class-box-l {
  float: left;
  margin: 20px 40px 0 20px;
  width: 240px;
  height: 160px;
  background: linear-gradient(180deg, #0c444e 0%, #713e5d 96.35%);
  border-radius: 8px;
}
.class-box-l-t {
  margin-top: 20px;
  font-weight: 900;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 0px 0px rgba(3, 33, 39, 0.64);
}
.class-box-l-d {
  width: 300px;
  margin: 40px 51px 0;
}
.class-box-l-d-v {
  float: left;
  width: 72px;
}
.class-box-l-d-t {
  float: left;
  width: 156px;
  font-weight: 900;
  font-size: 28px;
  color: #ffffff;
  background: transparent;
}
.class-box-r {
  float: left;
  text-align: left;
}
.class-box-r-t {
  margin: 30px 0 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #333333;
}
.class-box-r-i {
  font-size: 16px;
  line-height: 40px;
  color: #666666;
}
.class-box-r-btn {
  width: 144px;
  border-radius: 30px;
  font-size: 16px;
}
.main-box-r {
  width: 280px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 4px 4px 0px #c8c8c8;
  border-radius: 8px;
}
.el-icon-my-fire {
  background: url("~@/assets/icon-fire.png") no-repeat center center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  /* background-size: cover;*/
}
.el-icon-my-fire:before {
  content: "树";
  font-size: 16px;
  visibility: hidden;
}
.el-icon-my-fire {
  font-size: 16px;
  margin-right: 5px;
}
.el-icon-my-fire:before {
  content: "\e611";
}
.m-box {
  width: 280px;
  height: 56px;
  margin: 16px 0;
}
.m-box-1 {
  float: left;
  width: 22px;
  height: 56px;
}
.m-box-2 {
  float: left;
  width: 158px;
  height: 56px;
  font-size: 14px;
  line-height: 20px;
}
.m-box-3 {
  float: right;
  width: 88px;
  height: 56px;
}
.m-box-img {
  width: 16px;
  height: 16px;
  background: #9a7c30;
  border-radius: 16px;
}
.m-box-a {
  float: left;
  padding-left: 5px;
  width: 65px;
  overflow: hidden;
  white-space: nowrap;
}
.m-box-t {
  float: left;
  padding-left: 10px;
}
</style>
